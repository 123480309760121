import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

import Section from "../../common/Section";
import Image from "../../common/Image";

import BuildProjectCTA from "../BuildProjectCTA";
import Heading from "../../common/Heading";
import List from "../../common/List";
import LiveSymbol from "../../common/icons/Live";
import RichText from "../../common/RichText";
import Label from "../../common/Label";

const WhatsIncluded = ({ slice, context }) => {
  const data = useStaticQuery(graphql`
    query {
      prismicWhatsincludedBlock {
        data {
          included_items {
            item {
              richText
            }
          }
          drop_in_sessions {
            richText
          }
          what_you_need_items {
            item {
              richText
            }
          }
          what_you_don_t_need_items {
            item {
              richText
            }
          }
        }
      }
    }
  `);

  const assetsPreviewImage = slice.primary.assets_preview;
  const {
    included_items,
    drop_in_sessions,
    what_you_need_items,
    what_you_don_t_need_items,
  } = data.prismicWhatsincludedBlock.data;

  return (
    <div className="bg-limestone pb-6 sm:pb-10 lg:pt-10 lg:pb-24">
      <Section title="What's included" type="secondary" fullWidth>
        <div className="relative flex flex-col lg:flex-row">
          <div className="w-full lg:absolute left-0 illustration md:w-10/12 lg:w-5/12 my-6 lg:my-0">
            <Image
              image={assetsPreviewImage}
              alt="illustration of what is included such as design, style guide and all assets"
              className="max-w-2xl"
            />
          </div>
          <div className="container grid grid-cols-1 lg:grid-cols-2">
            <div className="lg:col-start-2 lg:col-end-3 pb-10">
              <List
                items={included_items}
                itemKey="item"
                numbered
                size="sm"
                className="pb-4"
                isRichText
              />
              <div className="bg-white py-8 ml-0 lg:-ml-16 pl-12 lg:pl-16 -mr-96 pr-96 rounded-tl-full rounded-bl-full">
                <div className="flex items-center gap-x-4 mb-2">
                  <Heading>Code reviews</Heading>
                  <Label uppercase colour="apricot" filled className="">
                    Coming soon
                  </Label>
                </div>
                <RichText field={drop_in_sessions.richText} size="sm" />
              </div>
            </div>
            <div className="needed col-start-1 2xl:col-start-2 col-end-3">
              <hr className="w-full mb-2" />
              <div className="flex flex-col sm:flex-row sm:gap-10">
                <div className="w-full sm:w-1/2">
                  <Heading level="h6" className="py-6">
                    What you need
                  </Heading>
                  <List
                    items={what_you_need_items}
                    itemKey="item"
                    size="sm"
                    isRichText
                  />
                </div>
                <div className="w-full sm:w-1/2">
                  <Heading level="h6" className="py-6">
                    What you don&apos;t need
                  </Heading>
                  <List
                    items={what_you_don_t_need_items}
                    itemKey="item"
                    size="sm"
                    isRichText
                  />
                </div>
              </div>
            </div>
            <div className="cta flex justify-center 2xl:justify-start col-start-1 col-end-3 2xl:col-end-2 2xl:row-start-2 mt-10 xl:mt-20">
              <BuildProjectCTA
                projectId={context.uid}
                saleSettings={context.saleSettings}
                userHasProject={context.userHasProject}
                hideIfEnrolled
                hideIfNotOnSale
                hideSalesMessage
              />
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default WhatsIncluded;

WhatsIncluded.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({}),
  }),
  context: PropTypes.shape({
    uid: PropTypes.string,
    saleSettings: PropTypes.shape({
      isProjectFree: PropTypes.bool,
      paymentLink: PropTypes.string,
      freeDownloadLink: PropTypes.string,
      priceList: PropTypes.arrayOf(
        PropTypes.shape({
          currency: PropTypes.string,
          number: PropTypes.number,
        }),
      ),
    }),
    userHasProject: PropTypes.bool,
  }),
};
