import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

function Padlock({ size = "base", className = null }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 15 20"
      className={cx(
        size === "sm" && "w-4",
        size === "base" && "w-8",
        size === "lg" && "w-10",
        className && className,
      )}
    >
      <path
        fill="#fff"
        d="M0 8.444C0 7.647.672 7 1.5 7h12c.828 0 1.5.647 1.5 1.444v10.112c0 .797-.672 1.444-1.5 1.444h-12C.672 20 0 19.353 0 18.556V8.444Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M10.8 12.375v-6.75c0-1.864-1.477-3.375-3.3-3.375-1.823 0-3.3 1.511-3.3 3.375v6.75c0 1.864 1.477 3.375 3.3 3.375 1.823 0 3.3-1.511 3.3-3.375ZM7.5 0C4.462 0 2 2.518 2 5.625v6.75C2 15.482 4.462 18 7.5 18s5.5-2.518 5.5-5.625v-6.75C13 2.518 10.538 0 7.5 0Z"
        clipRule="evenodd"
      />
      <path fill="#F0947F" d="M9 11.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
      <path
        fill="#F0947F"
        fillRule="evenodd"
        d="M7.5 12c.276 0 .5.172.5.385v1.23c0 .213-.224.385-.5.385s-.5-.172-.5-.385v-1.23c0-.213.224-.385.5-.385Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default Padlock;

Padlock.propTypes = {
  size: PropTypes.oneOf(["sm", "base", "lg"]),
  className: PropTypes.string,
};
