import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Label from "../common/Label";
import Heading from "../common/Heading";
import Paragraph from "../common/Paragraph";
import Button from "../common/Button";
import TickBubble from "./icons/TickBubble";

import {
  MC_AUDIENCE_ALL_CUSTOMERS,
  MC_WAITLIST_TAGS,
} from "../../constants/marketing";

function WaitingListForm({ projectId, userHasProject }) {
  const [mailChimpTag, setMailChimpTag] = useState(MC_WAITLIST_TAGS[undefined]);

  useEffect(() => {
    setMailChimpTag(MC_WAITLIST_TAGS[projectId]);
  }, [projectId]);

  if (userHasProject) {
    return (
      <div className="flex items-center">
        <TickBubble size="lg" className="mr-4" />
        <Label bold>Enrolled</Label>
      </div>
    );
  }

  return (
    <section className="bg-alpha-eucalyptus-20 py-6 px-6" id="waitlist-form">
      <Heading uppercase={false} bold className="block pb-3">
        Don’t miss out
      </Heading>
      <Label uppercase={false} bold className="block pb-4">
        Register your email below and we’ll let you know as soon as enrolment is
        open.
      </Label>
      <form
        action="https://codecaravan.us21.list-manage.com/subscribe/post"
        method="post"
      >
        <div className="flex flex-col xl:flex-row gap-4 md:gap-6 pb-4">
          <input type="hidden" name="u" value="6e48324a98f4850b4e96b1835" />
          <input type="hidden" name="id" value={MC_AUDIENCE_ALL_CUSTOMERS} />
          <input type="hidden" name="f_id" value="008bc4e1f0" />
          <input type="hidden" name="tags" value={mailChimpTag} />

          <input
            type="text"
            placeholder="Your first name"
            name="FNAME"
            id="mce-FNAME"
            autoCapitalize="off"
            autoCorrect="off"
            className="text-sm py-2 px-4 border-black border-2 placeholder:text-md placeholder:text-grey-medium placeholder:font-medium sm:mb-0 w-100 xl:w-48 max-w-xl"
          />
          <input
            type="email"
            placeholder="Your email"
            name="EMAIL"
            id="mce-EMAIL"
            autoCapitalize="off"
            autoCorrect="off"
            className="text-sm py-2 px-4 border-black border-2 placeholder:text-md placeholder:text-grey-medium placeholder:font-medium w-100 xl:w-80 max-w-xl"
          />
        </div>

        <Paragraph size="xxs" colour="charcoal">
          By submitting this form you agree to CodeCaravan emailing you to let
          you know when this project or similar opportunities become available.
          We won’t spam you and we’ll never sell your data.
        </Paragraph>
        <Button size="base" submit className="mt-6">
          Notify me
        </Button>
      </form>
    </section>
  );
}

export default WaitingListForm;

WaitingListForm.propTypes = {
  projectId: PropTypes.string,
  userHasProject: PropTypes.bool,
};
