export const getPriceByCurrency = (priceList, currency) => {
  if (!priceList || priceList.length == 0) return null;

  const priceInCurrency = priceList.find(
    (price) => price.currency === currency,
  );

  if (!priceInCurrency) {
    const defaultPrice = priceList.find((price) => price.currency === "USD");
    return `US$ ${defaultPrice.number}`;
  } else {
    switch (currency) {
      case "USD":
      default:
        return `US$ ${priceInCurrency?.number}`;
      case "GBP":
        return `£${priceInCurrency?.number}`;
      case "EUR":
        return `${priceInCurrency?.number}€`;
      case "AUD":
        return `AU$ ${priceInCurrency?.number}`;
      case "CAD":
        return `CA$ ${priceInCurrency?.number}`;
    }
  }
};
