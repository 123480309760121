import React from "react";
import PropTypes from "prop-types";

import Paragraph from "../../common/Paragraph";
import Heading from "../../common/Heading";
import Arrow from "../../common/Arrow";
import Skill from "../../common/icons/Skill";

import BuildProjectCTA from "../BuildProjectCTA";
import Flag from "../../common/Flag";

const Competencies = ({ slice, context }) => {
  const content = slice.primary;
  const competencies = slice.items;

  const { isSaleLive } = context.saleSettings;

  if (!competencies.length) return null;
  return (
    <section className="bg-limestone pt-20 pb-16">
      <div className="container">
        <div className="flex justify-between pb-10 md:pb-20">
          <Arrow />
          {content.objective_overview?.text && (
            <Heading
              level="h5"
              className="w-3/4 md:w-1/2 md:border-l pl-10 flex items-center"
            >
              {content.objective_overview?.text}
            </Heading>
          )}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-6 pb-10">
          {competencies &&
            competencies.map((competency) => {
              return (
                <div
                  key={competency.skill_title}
                  className="flex flex-col 2xl:flex-row gap-8 pb-8"
                >
                  <div className="">
                    <Skill className="w-32" name={competency.skill_icon_name} />
                    {competency.optional && (
                      <Flag
                        variant="secondary"
                        size="sm"
                        className="inline-flex mt-4"
                      >
                        Optional
                      </Flag>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <Heading level="h6" className="pb-4">
                      {competency.skill_title}
                    </Heading>
                    <Paragraph>{competency.skill_description}</Paragraph>
                  </div>
                </div>
              );
            })}
        </div>
        {isSaleLive && (
          <div className="flex justify-center py-10">
            <BuildProjectCTA
              projectId={context.uid}
              saleSettings={context.saleSettings}
              ctaCopy="Add these skills to your CV"
              userHasProject={context.userHasProject}
              hideIfEnrolled
              hideIfNotOnSale
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default Competencies;

Competencies.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({}),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  context: PropTypes.shape({
    uid: PropTypes.string,
    saleSettings: PropTypes.shape({
      isSaleLive: PropTypes.bool,
      isProjectFree: PropTypes.bool,
      paymentLink: PropTypes.string,
      freeDownloadLink: PropTypes.string,
      priceList: PropTypes.arrayOf(
        PropTypes.shape({
          currency: PropTypes.string,
          number: PropTypes.number,
        }),
      ),
    }),
    userHasProject: PropTypes.bool,
  }),
};
