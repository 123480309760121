import React from "react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";

import Paragraph from "../../common/Paragraph";
import Heading from "../../common/Heading";
import Shape from "../../common/Shape";

const HowItWorks = ({ slice }) => {
  const content = slice.primary.how_it_works_block.document.data;
  const steps = content.body[0].items;

  return (
    <section className="relative bg-white pt-20 pb-32 bg-[100%_10%] lg:bg-[100%_30%] bg-no-repeat bg-[length:100px_200px] lg:bg-[length:182px_364px]">
      <Shape
        type="semiCircle"
        className="h-48 w-24 md:h-72 top-10 md:w-36 lg:h-96 lg:w-48 text-lime absolute lg:top-20 2xl:top-1/4 right-0"
      />
      <div className="container">
        <Heading level="h2" className="w-9/12 pb-4">
          {content.main_heading}
        </Heading>
        <Heading
          level="h5"
          colour="eucalyptus"
          className="w-9/12 pb-10 md:pb-24"
        >
          {content.subheading}
        </Heading>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 relative">
          {steps.map((step) => {
            return (
              <div key={step.step_number} className="flex flex-col flex-1">
                <span className="font-dm-sans font-medium italic text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-grey-medium leading-none pb-4">
                  {step.step_number}.
                </span>
                <Heading
                  size="h6"
                  bold
                  className="pb-4 border-b border-black mb-8 lg:h-[75px] 3xl:h-auto"
                >
                  {step.step_heading}
                </Heading>
                <Paragraph>{step.step_description}</Paragraph>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;

HowItWorks.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      how_it_works_block: PropTypes.shape({
        document: PropTypes.shape({
          data: PropTypes.shape({
            main_heading: PropTypes.string,
            subheading: PropTypes.string,
            body: PropTypes.arrayOf(
              PropTypes.shape({
                items: PropTypes.arrayOf(
                  PropTypes.shape({
                    step_number: PropTypes.string,
                    step_heading: PropTypes.string,
                    step_description: PropTypes.string,
                  }),
                ),
              }),
            ),
          }),
        }),
      }),
    }),
  }),
};
