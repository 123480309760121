import React from "react";
import Image from "../../common/Image";

const FullWidthPreview = ({ slice }) => {
  return (
    <section>
      <Image image={slice.primary.main_image} quality={200} />
    </section>
  );
};

export default FullWidthPreview;
