import Competencies from "./Competencies";
import WhatsIncluded from "./WhatsIncluded";
import FullWidthPreview from "./FullWidthPreview";
import HowItWorks from "./HowItWorks";
import SessionSchedule from "./SessionSchedule";
import CtaBlock from "./CtaBlock";

export const components = {
  what_s_included: WhatsIncluded,
  responsive_screenshots_block: FullWidthPreview,
  live_session_schedule: SessionSchedule,
  learning_objectives: Competencies,
  how_it_works: HowItWorks,
  project_sale_cta: CtaBlock,
  // reviews:
};
