import React from "react";
import PropTypes from "prop-types";
import * as prismic from "@prismicio/client";

import Image from "../common/Image";
import Heading from "../common/Heading";
import Paragraph from "../common/Paragraph";
import Tag from "../common/Tag";
import Level from "../common/Level";

import BuildProjectCTA from "./BuildProjectCTA";
import Flag from "../common/Flag";
import Label from "../common/Label";
import WaitingListForm from "../common/WaitingListForm";

const dateTimeOptions = {
  day: "numeric",
  month: "long",
};
const dateTimeOptionsShort = {
  day: "numeric",
  month: "short",
};

function Intro({
  projectId,
  title,
  description,
  image,
  videoURL,
  levelLower,
  levelUpper,
  tags,
  flag,
  liveDate,
  saleSettings,
  userHasProject,
}) {
  return (
    <section className="grid grid-cols-1 lg:grid-cols-2 xl:grid-rows-[190px_1fr] gap-y-10 md:gap-y-12 lg:gap-y-8 lg:gap-x-16 pt-10 lg:pt-20 pb-20 lg:pb-40">
      {saleSettings.isSaleLive && flag && (
        <Flag pin="right" size="lg" className="hidden sm:block top-0 xl:top-24">
          {flag}
        </Flag>
      )}
      {!saleSettings.isSaleLive && (
        <Flag pin="right" size="lg" className="top-0 xl:top-24 z-10">
          Coming soon
        </Flag>
      )}

      <div className="col-start-1 row-start-2 lg:row-start-1 lg:row-span-2 mx-auto">
        {videoURL ? (
          <video
            src={videoURL}
            poster={image.url}
            preload="auto"
            autoPlay
            muted
            loop
            className="w-full lg:w-[600px] border-black border-[6px] rounded-[18px] lg:border-8"
          />
        ) : (
          <Image
            image={image}
            alt={`preview of the ${title} project design`}
            withFrame
          />
        )}
        <div className="flex flex-wrap gap-2 sm:gap-3 lg:gap-4 mt-6 xl:mt-10">
          {tags.map((tag, i) => {
            return (
              <Tag
                key={i}
                name={tag.tag_name}
                isRequired={tag.language_is_required}
              />
            );
          })}
        </div>
      </div>

      <div className="col-start-1 lg:col-start-2 row-start-1">
        <div className="flex justify-between pb-4 lg:pb-8 border-b border-black mb-4 md:mb-6 lg:mb-8">
          <Heading level="h1" uppercase className="">
            {title}
          </Heading>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 sm:items-center justify-between">
          <div className="flex items-center gap-3">
            {liveDate && (
              <>
                <Label filled bold>
                  Release date
                </Label>
                <Label className="inline-block lg:hidden xl:inline-block">
                  {prismic
                    .asDate(liveDate)
                    .toLocaleString("en-GB", dateTimeOptions)}
                </Label>
                <Label className="hidden lg:inline-block xl:hidden">
                  {prismic
                    .asDate(liveDate)
                    .toLocaleString("en-GB", dateTimeOptionsShort)}
                </Label>
              </>
            )}
          </div>
          <Level lowerLevel={levelLower} upperLevel={levelUpper} withLabel />
        </div>
      </div>

      <div className="col-start-1 lg:col-start-2">
        <Paragraph size="lg" className="pb-10">
          {description}
        </Paragraph>
        {saleSettings.isSaleLive ? (
          <BuildProjectCTA
            projectId={projectId}
            saleSettings={saleSettings}
            userHasProject={userHasProject}
          />
        ) : (
          <WaitingListForm
            projectId={projectId}
            userHasProject={userHasProject}
          />
        )}
      </div>
    </section>
  );
}

export default Intro;

Intro.propTypes = {
  projectId: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  videoURL: PropTypes.string,
  flag: PropTypes.string,
  liveDate: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      isRequired: PropTypes.bool,
    }),
  ),
  saleSettings: PropTypes.shape({
    isSaleLive: PropTypes.bool,
    isProjectFree: PropTypes.bool,
    paymentLink: PropTypes.string,
    freeDownloadLink: PropTypes.string,
    priceList: PropTypes.arrayOf(
      PropTypes.shape({
        currency: PropTypes.string,
        number: PropTypes.number,
      }),
    ),
    priceListDiscounted: PropTypes.arrayOf(
      PropTypes.shape({
        currency: PropTypes.string,
        number: PropTypes.number,
      }),
    ),
  }),
  levelLower: PropTypes.oneOf(["1", "2", "3", "4", "5"]),
  levelUpper: PropTypes.oneOf(["1", "2", "3", "4", "5"]),
  userHasProject: PropTypes.bool,
};
