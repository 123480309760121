import * as React from "react";
import { graphql } from "gatsby";

import CustomHead from "../../components/common/Head";
import IndividualProjectTemplate from "../../templates/project";

export const query = graphql`
  query SpecificProjectBySlug($data__slug: String!) {
    prismicProject(data: { slug: { eq: $data__slug } }) {
      id
      uid
      data {
        page_title
        page_description
        slug
        short_name {
          text
        }
        long_description {
          text
        }
        thumbnail {
          gatsbyImageData(
            placeholderImgixParams: { blur: 10 }
            placeholder: BLURRED
          )
          alt
          url
        }
        video_preview {
          url
        }
        flag
        project_type
        project_level_lower_end
        project_level_upper_end
        tags {
          tag_name
          language_is_required
        }
        status
        public_release_date
        discord_project_channel {
          url
        }
        price {
          currency
          number
        }
        discounted_price {
          currency
          number
        }
        isprojectfree
        payment_link
        free_download_link
        special_message {
          text
          richText
        }
        body1 {
          ... on PrismicProjectDataBody1LearningObjectives {
            slice_type
            primary {
              objective_overview {
                text
              }
            }
            items {
              skill_title
              skill_description
              skill_icon_name
              optional
            }
          }
          ... on PrismicProjectDataBody1ResponsiveScreenshotsBlock {
            id
            slice_type
            primary {
              main_image {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholderImgixParams: { blur: 10 }
                  placeholder: BLURRED
                )
              }
            }
          }
          ... on PrismicProjectDataBody1HowItWorks {
            slice_type
            primary {
              how_it_works_block {
                type
                document {
                  ... on PrismicHowItWorksBlock {
                    id
                    data {
                      main_heading
                      subheading
                      body {
                        ... on PrismicHowItWorksBlockDataBodySteps {
                          id
                          items {
                            step_description
                            step_heading
                            step_number
                          }
                          slice_type
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProjectDataBody1WhatSIncluded {
            slice_type
            primary {
              assets_preview {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholderImgixParams: { blur: 10 }
                  placeholder: BLURRED
                )
              }
            }
          }
          ... on PrismicProjectDataBody1ProjectSaleCta {
            slice_type
            primary {
              custom_cta_label
            }
          }
        }
      }
    }
  }
`;

const individualProjectPageWithData = ({ data, location }) => {
  return (
    <IndividualProjectTemplate
      data={data?.prismicProject}
      location={location}
    />
  );
};

export const Head = ({ data }) => {
  const pageMeta = {
    title: data.prismicProject.data.page_title,
    description: data.prismicProject.data.page_description,
  };

  return <CustomHead pageMeta={pageMeta} />;
};

export default individualProjectPageWithData;
