import React from "react";
import PropTypes from "prop-types";

function LiveSymbol({ colour = "black", className }) {
  return (
    <svg
      className={className && className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 208 73"
    >
      <rect
        width="206"
        height="71"
        x="1"
        y="1"
        stroke={colour === "white" ? "#fff" : "#111"}
        strokeWidth="2"
        rx="35.5"
      />
      <path
        fill={colour === "white" ? "#fff" : "#111"}
        d="M30.692 54V17.6h5.512v32.032H52.74V54H30.692Zm27.98 0V17.6h5.513V54h-5.513Zm23.663 0L68.867 17.6h5.876l10.92 30.68L96.635 17.6h5.824L88.991 54h-6.656Zm24.783 0V17.6h23.14v4.472H112.63v11.336h16.068v4.368H112.63v11.752h17.628V54h-23.14Z"
      />
      <circle cx="168" cy="37" r="13" fill="#F0947F" />
    </svg>
  );
}

export default LiveSymbol;

LiveSymbol.propTypes = {
  colour: PropTypes.oneOf(["black", "white"]),
  className: PropTypes.string,
};
