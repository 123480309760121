import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

function TickBubble({ size = "base", className = null }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 42 42"
      className={cx(
        size === "sm" && "w-4",
        size === "base" && "w-8",
        size === "lg" && "w-10",
        className && className,
      )}
    >
      <path
        fill="#299F98"
        d="M42 21c0 11.598-9.402 21-21 21S0 32.598 0 21 9.402 0 21 0s21 9.402 21 21Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m32.758 13.652-14.485 16.85-8.006-8.634 1.466-1.36 6.483 6.991 13.025-15.15 1.517 1.303Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default TickBubble;

TickBubble.propTypes = {
  size: PropTypes.oneOf(["sm", "base", "lg"]),
  className: PropTypes.string,
};
