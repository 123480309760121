import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import * as prismic from "@prismicio/client";

import Label from "../../common/Label";
import Paragraph from "../../common/Paragraph";
import Heading from "../../common/Heading";
import LiveSymbol from "../../common/icons/Live";
import Padlock from "../../common/icons/Padlock";
import Calendar from "../../common/icons/Calendar";
import BuildProjectCTA from "../BuildProjectCTA";
import Arrow from "../../common/Arrow";
import Button from "../../common/Button";

const dateTimeOptions = {
  weekday: "long",
  day: "numeric",
  month: "short",
  year: "numeric",
  hour: "numeric",
  timeZoneName: "short",
  hourCycle: "h12",
};

function SessionSchedule({ slice, context }) {
  const [nextSessionIndex, setNextSessionIndex] = useState(null);

  useEffect(() => {
    const findNextSessionIndex = () => {
      const allSessions = slice.items;
      // find session that is:
      // - today or after today (= in the future)
      // - the first item in the array (since items are chronological)
      const today = new Date().toJSON();
      const nextIndex = allSessions.findIndex((session) => {
        return session.session_time === today || session.session_time > today;
      });
      setNextSessionIndex(nextIndex);
    };

    if (slice.items?.length) {
      findNextSessionIndex();
    }
  }, [nextSessionIndex, slice.items]);

  // const hideDateIfPast = (item) => {
  //   const today = new Date().toJSON();
  //   return session.session_time < today;
  // };

  const renderSessionFlag = (session) => {
    if (context.userHasProject) {
      return null;
    } else if (session.session_access === "free") {
      return (
        <Label
          size="lg"
          colour="eucalyptus"
          className={cx(
            "italic py-1 px-3 leading-tight absolute top-[-1px] left-0 bg-white",
          )}
        >
          open to all
        </Label>
      );
    } else {
      return (
        <Label
          size="lg"
          colour="white"
          className={cx(
            "italic py-1 px-3 leading-tight absolute top-[-1px] left-0 bg-apricot",
          )}
        >
          <Padlock size="sm" className="mb-[2px]" />
        </Label>
      );
    }
  };

  const renderSessionZoomLinkOrEmbed = (session) => {
    if (session.session_access === "free" && session.watch_link.url) {
      return session.watch_link.url.includes("youtube") ? (
        <Button
          externalUrl="https://youtu.be/QxhI-ivK0hA?si=1oDJw3LDC9Ua1lxt"
          variant="ghost"
          size="xs"
          className="inline-flex items-center gap-2 mt-5"
        >
          Watch video
          <Arrow type="small" className="text-limestone w-4 h-4 rotate-180" />
        </Button>
      ) : (
        <Label colour="limestone" bold className="block mt-5">
          <a
            href={session.watch_link.url}
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer inline-flex items-center mt-4 underline"
          >
            Join session for free
            <Arrow
              type="small"
              className="text-limestone w-4 h-4 ml-2 rotate-180"
            />
          </a>
        </Label>
      );
    } else if (context.userHasProject) {
      return (
        session.watch_link.url && (
          <Label colour="limestone" bold className="block mt-5">
            <a
              href={session.watch_link.url}
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer flex items-end gap-3 mt-4 underline"
            >
              Join the session
              <LiveSymbol colour="white" className="w-20" />
            </a>
          </Label>
        )
      );
    } else {
      null;
    }
  };

  return (
    <div className="bg-alpha-eucalyptus">
      <section className="container py-12 md:py-20">
        <div className="pt-8 md:pt-14 mb-10 md:mb-20">
          <Heading
            level="h3"
            className="pb-6 text-white flex flex-col md:flex-row gap-2 md:gap-6"
          >
            <LiveSymbol colour="white" className="w-32" />
            session schedule
          </Heading>
          <Heading level="h5" className="text-lime">
            {slice.primary.subheading}
          </Heading>
        </div>

        <div className="flex flex-col">
          {slice.items.map((session, index) => {
            return (
              <div
                key={`session--${index}`}
                className="schedule-row relative border-t-1 border-white pt-12 mb-10"
              >
                {renderSessionFlag(session)}
                <div className="grid md:grid-cols-[1fr_2fr] lg:grid-cols-[1fr_2fr_340px] 2xl:grid-cols-[1fr_2fr_1fr] gap-x-4">
                  <Label size="base">{session.session_topic}</Label>
                  <div className="lg:contents">
                    <div className="div">
                      <Paragraph>{session.session_description}</Paragraph>
                      {renderSessionZoomLinkOrEmbed(session)}
                    </div>
                    <div
                      className={cx(
                        "py-3 md:py-4 lg:ml-3 pl-6 lg:pl-8 -mr-96 pr-96 lg:pr-0 my-6 lg:my-0 rounded-tl-full rounded-bl-full h-fit whitespace-nowrap inline-flex gap-3 items-center leading-relaxed",
                        nextSessionIndex === index
                          ? "bg-alpha-white-40"
                          : "border-1 border-alpha-white-40 bg-none",
                      )}
                    >
                      <Calendar className="w-6" />
                      {session.session_time ? (
                        <Label className="">
                          {prismic
                            .asDate(session.session_time)
                            .toLocaleString("en-GB", dateTimeOptions)}
                        </Label>
                      ) : (
                        <Label className="">Date to be announced</Label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex justify-center">
          <BuildProjectCTA
            projectId={context.uid}
            saleSettings={context.saleSettings}
            ctaCopy="Enrol to unlock all sessions"
            userHasProject={context.userHasProject}
            hideIfEnrolled
            hideIfNotOnSale
            hideSalesMessage
          />
        </div>
      </section>
    </div>
  );
}

export default SessionSchedule;

SessionSchedule.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({}),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  context: PropTypes.shape({
    uid: PropTypes.string,
    saleSettings: PropTypes.shape({
      isProjectFree: PropTypes.bool,
      paymentLink: PropTypes.string,
      freeDownloadLink: PropTypes.string,
      priceList: PropTypes.arrayOf(
        PropTypes.shape({
          currency: PropTypes.string,
          number: PropTypes.number,
        }),
      ),
    }),
    userHasProject: PropTypes.bool,
  }),
};
