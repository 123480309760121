import React, { useState, useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

import { login } from "../../utils/auth";
import { getPriceByCurrency } from "../../utils/pricing";

import { AuthContext } from "../../context/authContext";
import { MetaContext } from "../../context/metaContext";

import Button from "../common/Button";
import TickBubble from "../common/icons/TickBubble";
import Label from "../common/Label";

function BuildProjectCTA({
  projectId,
  saleSettings,
  ctaCopy = "Build this",
  hideIfEnrolled = false,
  hideIfNotOnSale = false,
  hideSalesMessage = false,
  userHasProject = false,
}) {
  const { user, isLoggedIn, loading } = useContext(AuthContext);
  const {
    isProjectFree,
    freeDownloadLink,
    paymentLink,
    priceList,
    priceListDiscounted,
    isSaleLive,
    specialSalesMessage,
  } = saleSettings;

  const [isRedirecting, setIsRedirecting] = useState(false);
  const [purchaseIntent, setPurchaseIntent] = useState(false);
  const [orderLink, setOrderLink] = useState("");

  const { currency } = useContext(MetaContext);
  const priceInCurrency = useMemo(() => {
    return getPriceByCurrency(priceList, currency);
  }, [priceList, currency]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setPurchaseIntent(!!urlParams.get("redirectTo"));
  }, []);

  useEffect(() => {
    if (isLoggedIn && purchaseIntent) {
      setIsRedirecting(true);
    } else {
      setIsRedirecting(false);
    }
  }, [isLoggedIn, purchaseIntent]);

  useEffect(() => {
    if (user) {
      setOrderLink(
        isProjectFree
          ? `${freeDownloadLink}?buyer_email=${user.email}`
          : `${paymentLink}?prefilled_email=${user.email}&client_reference_id=${user.id}`,
      );
    } else {
      setOrderLink(isProjectFree ? freeDownloadLink : paymentLink);
    }
  }, [isProjectFree, freeDownloadLink, paymentLink, user, isLoggedIn]);

  const logInAndPurchase = () => {
    // Pass orderLink in to login() method which will add it to URL params.
    // AuthProvider is looking out for any 'redirectTo' URL params on auth state change and will redirect to the orderLink (Stripe or SendOwl) once logged in
    login(orderLink);
    // Note: Both Stripe and SendOwl products should be set up to redirect to
    // path /purchase-complete/:projectId page from within Stripe/SendOwl platforms
  };

  const purchase = () => {
    // Go straight to Stripe or SendOwl
    navigate(orderLink);
    // SendOwl or Stripe will redirect to purchase-complete/:projectId on order completion.
    // Stripe will handle db update via payments Cloud function
    // whereas for SendOwl it's the purchase-complete page that triggers an update of the user's products array in firestore
  };

  if (userHasProject) {
    return hideIfEnrolled ? null : (
      <div className="flex items-center">
        <TickBubble size="lg" className="mr-4" />
        <Label bold>Enrolled</Label>
      </div>
    );
  }

  if (!isSaleLive) {
    return (
      hideIfNotOnSale && (
        <Button anchor="#waitlist-form" variant="secondary">
          Join the waitlist
        </Button>
      )
    );
  }

  if (isRedirecting) {
    return (
      <div className="flex items-center">
        <Button loading loadingLabel="Redirecting" />
      </div>
    );
  }

  if (!orderLink) {
    return;
  }

  if (isLoggedIn && user) {
    return (
      <div className="grid sm:grid-cols-[max-content_1fr] gap-x-10 gap-y-4 flex-wrap items-baseline">
        <Button action={purchase} loading={loading} size="lg">
          {ctaCopy}
        </Button>
        <div className="flex flex-wrap items-baseline justify-center sm:justify-normal gap-x-4 gap-y-2">
          {isProjectFree ? (
            <p className="font-inter text-xl">FREE</p>
          ) : (
            <p className="font-inter text-xl">{priceInCurrency}</p>
          )}
          <div className="">
            {specialSalesMessage.length > 0 && !hideSalesMessage && (
              <Label colour="apricot" bold>
                {specialSalesMessage}
              </Label>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex items-center gap-x-10 gap-y-4 flex-wrap">
        <Button action={logInAndPurchase} loading={loading} size="lg">
          {isProjectFree ? "Build this for free" : ctaCopy}
        </Button>
        {!isProjectFree && (
          <p className="font-inter text-xl">{priceInCurrency}</p>
        )}
        {specialSalesMessage.length > 0 && !hideSalesMessage && (
          <Label colour="apricot" bold>
            {specialSalesMessage}
          </Label>
        )}
      </div>
    );
  }
}

export default BuildProjectCTA;

BuildProjectCTA.propTypes = {
  projectId: PropTypes.string,
  ctaCopy: PropTypes.string,
  hideIfEnrolled: PropTypes.bool,
  hideIfNotOnSale: PropTypes.bool,
  hideSalesMessage: PropTypes.bool,
  userHasProject: PropTypes.bool,
  saleSettings: PropTypes.shape({
    isProjectFree: PropTypes.bool,
    paymentLink: PropTypes.string,
    freeDownloadLink: PropTypes.string,
    priceList: PropTypes.arrayOf(
      PropTypes.shape({
        currency: PropTypes.string,
        number: PropTypes.number,
      }),
    ),
    priceListDiscounted: PropTypes.arrayOf(
      PropTypes.shape({
        currency: PropTypes.string,
        number: PropTypes.number,
      }),
    ),
    isSaleLive: PropTypes.bool,
    specialSalesMessage: PropTypes.string,
  }),
};
