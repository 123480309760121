import React from "react";

function Calendar({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      className={className && className}
    >
      <path
        stroke="#111"
        strokeLinecap="round"
        d="M1 14.333h30m-20 8.334h10M9.333 1v6.667M22.667 1v6.667M7.667 31h16.666c3.143 0 4.714 0 5.69-.976.977-.977.977-2.548.977-5.69V12.666c0-3.143 0-4.714-.976-5.69C29.047 6 27.476 6 24.334 6H7.666c-3.143 0-4.714 0-5.69.976C1 7.953 1 9.524 1 12.666v11.667c0 3.143 0 4.714.976 5.69.977.977 2.548.977 5.69.977Z"
      />
    </svg>
  );
}

export default Calendar;
