import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { SliceZone } from "@prismicio/react";

import Layout from "../components/common/Layout";
import Intro from "../components/project/Intro";
import { components } from "../components/project/slices";

import { AuthContext } from "../context/authContext";

const IndividualProject = ({ data, location }) => {
  const { user } = useContext(AuthContext);
  const [userHasProject, setUserHasProject] = useState(false);

  const projectSlices = data.data.body1;

  const projectData = {
    uid: data.uid,
    name: data.data.short_name.text,
    description: data.data.long_description.text,
    thumbnail: data.data.thumbnail,
    videoPreviewURL: data.data.video_preview.url,
    flag: data.data.flag,
    type: data.data.project_type,
    levelLower: data.data.project_level_lower_end,
    levelUpper: data.data.project_level_upper_end,
    tags: data.data.tags,
    status: data.data.status,
    liveDate: data.data.public_release_date,
    saleSettings: {
      isProjectFree: data.data.isprojectfree,
      paymentLink: data.data.payment_link,
      freeDownloadLink: data.data.free_download_link,
      priceList: data.data.price,
      priceListDiscounted: data.data.discounted_price,
      isSaleLive:
        data.data.status === "live" || data.data.status === "pre_sale",
      specialSalesMessage: data.data.special_message.text,
    },
    userHasProject: userHasProject,
  };

  useEffect(() => {
    if (user) {
      const userProjects = user.products.filter(
        (prod) => prod.productId === data.uid,
      );
      setUserHasProject(userProjects.length > 0 ? true : false);
    } else {
      setUserHasProject(false);
    }
  }, [data.uid, user]);

  if (!data) return null;

  return (
    <Layout>
      <div className="container">
        <Intro
          projectId={projectData.uid}
          title={projectData.name}
          description={projectData.description}
          image={projectData.thumbnail}
          videoURL={projectData.videoPreviewURL}
          levelLower={projectData.levelLower}
          levelUpper={projectData.levelUpper}
          tags={projectData.tags}
          flag={projectData.flag}
          liveDate={projectData.liveDate}
          status={projectData.status}
          saleSettings={projectData.saleSettings}
          userHasProject={userHasProject}
        />
      </div>
      <SliceZone
        slices={projectSlices}
        components={components}
        context={projectData}
      />
    </Layout>
  );
};

IndividualProject.propTypes = {
  data: PropTypes.shape({
    uid: PropTypes.string,
    data: PropTypes.shape({
      short_name: PropTypes.shape({ text: PropTypes.string }),
      long_description: PropTypes.shape({ text: PropTypes.string }),
      thumbnail: PropTypes.shape({ url: PropTypes.string }),
      video_preview: PropTypes.shape({ url: PropTypes.string }),
      flag: PropTypes.string,
      project_type: PropTypes.string,
      project_level_lower_end: PropTypes.string,
      project_level_upper_end: PropTypes.string,
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          isRequired: PropTypes.bool,
        }),
      ),
      status: PropTypes.string,
      isprojectfree: PropTypes.bool,
      payment_link: PropTypes.string,
      free_download_link: PropTypes.string,
      price: PropTypes.arrayOf(
        PropTypes.shape({
          currency: PropTypes.string,
          number: PropTypes.number,
        }),
      ),
      discounted_price: PropTypes.arrayOf(
        PropTypes.shape({
          currency: PropTypes.string,
          number: PropTypes.number,
        }),
      ),
      body1: PropTypes.array,
    }),
  }),
};

export default IndividualProject;
