import React from "react";
import PropTypes from "prop-types";

import BuildProjectCTA from "../BuildProjectCTA";

function CtaBlock({ slice, context }) {
  return (
    <section className="container flex justify-center mx-auto py-10">
      <BuildProjectCTA
        projectId={context.uid}
        saleSettings={context.saleSettings}
        ctaCopy={`Build ${context.name}`}
        userHasProject={context.userHasProject}
        hideIfEnrolled
        hideIfNotOnSale
      />
    </section>
  );
}

export default CtaBlock;

CtaBlock.propTypes = {
  context: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string,
    saleSettings: PropTypes.shape({
      isProjectFree: PropTypes.bool,
      paymentLink: PropTypes.string,
      freeDownloadLink: PropTypes.string,
      priceList: PropTypes.arrayOf(
        PropTypes.shape({
          currency: PropTypes.string,
          number: PropTypes.number,
        }),
      ),
    }),
    userHasProject: PropTypes.bool,
  }),
};
